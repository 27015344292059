import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    signal,
    Signal,
    WritableSignal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import dayjs, { Dayjs } from 'dayjs';

import { PrivaContentSectionModule } from '@priva/components/content-section';
import { PrivaLoaderModule } from '@priva/components/loader';
import { PrivaSpinnerModule } from '@priva/components/spinner';
import { PrivaTableModule } from '@priva/components/table';
import { PrivaTagModule } from '@priva/components/tag';
import { PrivaTileModule } from '@priva/components/tile';
import { PrivaTitleModule } from '@priva/components/title';
import { selectCurrentCropsEarliestStartDate } from '@priva/masterdata';

import { DayDatePickerComponent } from '@app/date-picker';
import { DateFormat, LocaleService, NumberToLocalePipe } from '@app/utilities';

import { loadAnomaliesIfNeeded, selectAnomaliesIsLoading } from '../state/anomalies';
import { selectTableData } from './anomalies-page.selectors';
import { Row, TableCellId, TableData } from './crops-anomalies.model';

@Component({
    selector: 'app-crops-anomalies',
    standalone: true,
    imports: [
        PrivaTitleModule,
        PrivaTileModule,
        PrivaContentSectionModule,
        PrivaTableModule,
        TranslateModule,
        PrivaLoaderModule,
        PrivaSpinnerModule,
        NumberToLocalePipe,
        PrivaTagModule,
        DayDatePickerComponent,
    ],
    templateUrl: './crops-anomalies.component.html',
    styleUrl: './crops-anomalies.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropsAnomaliesComponent {
    private readonly _store = inject(Store);
    private readonly _localeService = inject(LocaleService);
    private readonly locale: string;
    private readonly yesterday = dayjs().subtract(1, 'day');
    private readonly dateRequest = signal(this.yesterday);

    public readonly TableCellId = TableCellId;
    public readonly maxDate = signal(this.yesterday);
    public readonly displayDate = computed(() => this.yesterday.locale(this.locale).format('D MMMM'));
    public readonly minWidth = '112';
    public readonly isLoading = this._store.selectSignal(selectAnomaliesIsLoading);

    public placeholderDate = signal(this.yesterday.format(DateFormat));
    public displayDatePicker: WritableSignal<Dayjs> = signal(this.yesterday);
    public cropsEarliestStartDate = this._store.selectSignal(selectCurrentCropsEarliestStartDate);
    public tableData: Signal<TableData> = this._store.selectSignal(selectTableData);

    public isHighlighted(row: Row) {
        return !row[0].isCompartment;
    }

    constructor() {
        this.locale = this._localeService.language;
    }

    public onDateChange(selectedDate: Dayjs): void {
        if (selectedDate) {
            this.dateRequest.set(selectedDate);
            this.placeholderDate.set(selectedDate.format(DateFormat));
        }
        this._store.dispatch(
            loadAnomaliesIfNeeded({
                cropIds: this.tableData()?.cropIds,
                metricIds: this.tableData()?.metricIds,
                anomaliesRequestDate: this.dateRequest(),
            }),
        );
    }
}
