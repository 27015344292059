<priva-content-section class="margin-bottom-4">
    <div class="dashboard-header">
        <priva-selection-group
            type="radio"
            name="options"
            theme="onlightgray"
            prefix="status"
            [items]="items()"
            [selectedItem]="selectedItem()"
            (toggle)="onToggleChange($event)"
        />
        <div
            class="dashboard-header__date-select"
            [class.day-picker__week]="selectedRequestDateRange() === 'weekly'"
        >
            @if (selectedRequestDateRange() === 'daily') {
                <app-day-date-picker
                    [minDate]="cropsEarliestStartDate()"
                    [maxDate]="maxDate()"
                    [placeholder]="placeholderDate()"
                    [date]="selectedDate()"
                    (onChange)="onDateChange($event)"
                />
            } @else {
                @if (isConfigReady()) {
                    <dp-date-picker
                        priva-datepicker
                        mode="day"
                        theme="onlightgray"
                        alignment="left"
                        data-test="week-picker"
                        [placeholder]="placeholderDate()"
                        [displayDate]="displayDatePicker()"
                        [config]="weekDatePickerConfig"
                        (onChange)="onDateChange($event)"
                    />
                }
            }
        </div>
    </div>
</priva-content-section>
<priva-content-section class="dashboard-main">
    <priva-tile [hasHeader]="false">
        @if (dashboardData() && !isLoading()) {
            @let tableData = dashboardData();
            <priva-table
                type="collapsed"
                class="dashboard-main__table"
                [rows]="tableData.cropRowData"
                [hasStickyHeader]="true"
                [hasStickyFirstColumn]="true"
                [hasClickableRows]="true"
                (rowClick)="onSelectCrop($event.row[0])"
            >
                @let columnData = tableData.columnData;
                @for (column of columnData; track column; let idx = $index) {
                    <ng-template
                        [priva-table-column]="column.title"
                        [minWidth]="minWidth"
                        [justify]="'center'"
                        [alignment]="column.alignment || 'start'"
                        [headerTemplateRef]="{ template: headerTemplate, context: { data: column } }"
                        let-item="row"
                    >
                        @switch (column.id) {
                            @case (TableColumnID.Crops) {
                                <ng-container>
                                    <div class="crop-info">
                                        <strong>{{ item[idx]?.name }}</strong>
                                        @if (!item[idx].isAllTargetsSet) {
                                            <a
                                                #popoverToggle
                                                priva-button
                                                priva-popover-trigger
                                                priva-toggle-stop-click-propagation
                                                iconClass="far fa-circle-exclamation"
                                                iconState="only"
                                                importance="tertiary"
                                                size="sm"
                                                class="crop-info__icon"
                                                [attr.data-test]="'target-not-set-' + item[idx].cropId"
                                                [href]="'crops/dashboard/' + item[idx].cropId + '/plan'"
                                                (popoverToggle)="tooltipTable[item[idx]?.name] = $event"
                                            ></a>
                                            @if (tooltipTable[item[idx].name]) {
                                                <priva-popover
                                                    placement="top"
                                                    appendTo="body"
                                                    [relativeTo]="popoverToggle"
                                                    (popoverClose)="tooltipTable[item.Timestamp] = null"
                                                >
                                                    {{ 'APP.CROP_DASHBOARD.TARGET_NOT_SET' | translate }}
                                                </priva-popover>
                                            }
                                        }
                                    </div>
                                </ng-container>
                            }
                            @case (TableColumnID.Kpi) {
                                <div>
                                    <app-value-with-deviation [item]="item[idx]" />
                                </div>
                            }
                        }
                    </ng-template>
                }

                <ng-template #headerTemplate let-column="data">
                    @switch (column.id) {
                        @case (TableColumnID.Crops) {
                            <div [attr.data-test]="'dashboard-crops-counter'">
                                {{ (column.title | translate) + ' (' + column.activeCrops + ')' }}
                            </div>
                        }
                        @case (TableColumnID.Kpi) {
                            <div>{{ column.title | translate }}</div>
                            <div class="kpi-unit">{{ column.unit | translate }}</div>
                        }
                    }
                </ng-template>
            </priva-table>
        } @else {
            <priva-loader [hasFooter]="true">
                <priva-spinner size="lg" />
                <ng-container data-footer>{{ 'APP.CROPS.LOADING.DASHBOARD' | translate }}</ng-container>
            </priva-loader>
        }
    </priva-tile>
</priva-content-section>
